// Event Listeners
function safeAddEventListener (selector, event, handler) {
  const element = document.querySelector(selector)
  if (element) {
    element.addEventListener(event, handler)
  }
}

// Usage
safeAddEventListener('#parseFileBtn', 'click', parseCSVFromFile)
safeAddEventListener('#parseTextBtn', 'click', parseCSVFromText)
safeAddEventListener('#exportBtn', 'click', exportCSV)
safeAddEventListener('#saveSessionBtn', 'click', saveSession)
safeAddEventListener('#loadSessionBtn', 'click', loadSession)
safeAddEventListener('#filterInput', 'input', filterTable)
safeAddEventListener('#goToRow', 'change', goToRow)

let csvData = [] // Global storage for parsed CSV data
const sortDirection = {} // To track the sorting direction for each column
const rowHeight = 30 // Approximate height of each row (in px)
const buffer = 10 // Number of buffer rows above and below the viewport
let visibleStartIndex = 0 // Start index of visible rows
let visibleEndIndex = 0 // End index of visible rows
let totalRows = 0 // Total rows in the table

let lastStartIndex = 0 // Track the last rendered range to prevent redundant renders
let lastEndIndex = 0

function goToRow () {
  const rowInput = document.getElementById('goToRow').value.trim()
  const rowNumber = parseInt(rowInput, 10)

  if (isNaN(rowNumber) || rowNumber < 1 || rowNumber >= csvData.length) {
    return // Do nothing if the input is invalid
  }

  const tableContainer = document.getElementById('csvTable').querySelector('.uk-overflow-auto')
  const rowTopPosition = (rowNumber - 1) * rowHeight

  // Scroll the table container to the calculated position
  tableContainer.scrollTo({
    top: rowTopPosition,
    behavior: 'auto' // Immediate scrolling
  })

  // Update visible rows and highlight the target row
  updateVisibleRows(csvData)
  highlightRow(rowNumber)
}

/*
 Alternatively, if you prefer smooth scrolling, you can use the following approach:

 function goToRow() {
 const rowInput = document.getElementById('goToRow').value.trim();
 const rowNumber = parseInt(rowInput, 10);

 if (isNaN(rowNumber) || rowNumber < 1 || rowNumber >= csvData.length) {
 return; // Do nothing if the input is invalid
 }

 const tableContainer = document.getElementById('csvTable').querySelector('.uk-overflow-auto');
 const rowTopPosition = (rowNumber - 1) * rowHeight;

 // Scroll the table container to the calculated position
 tableContainer.scrollTo({
 top: rowTopPosition,
 behavior: 'smooth', // Smooth scrolling
 });

 // Listen for the scroll event to detect when scrolling has reached the target position
 function onScroll() {
 // Check if scrollTop is close to the target position
 if (Math.abs(tableContainer.scrollTop - rowTopPosition) <= 1) {
 tableContainer.removeEventListener('scroll', onScroll);
 updateVisibleRows(csvData);
 highlightRow(rowNumber);
 }
 }

 tableContainer.addEventListener('scroll', onScroll);
 }
 */

function highlightRow (rowNumber) {
  const spacer = document.querySelector('.spacer')

  // Clear previous highlights
  Array.from(spacer.children).forEach((row) => {
    row.style.backgroundColor = ''
  })

  const targetRow = spacer.querySelector(`.row-${rowNumber}`)
  if (targetRow) {
    targetRow.style.backgroundColor = '#ffeaa7' // Highlight color
  }
}

function filterTable () {
  const filterValue = document.getElementById('filterInput').value.toLowerCase()

  // Filter the csvData (excluding the header row)
  const filteredData = [csvData[0]].concat(
    csvData.slice(1).filter((row) => {
      return row.some((cell) => cell.toLowerCase().includes(filterValue))
    })
  )

  // Re-render the table with the filtered data
  displayTableVirtualized(filteredData)
}

function displayTableVirtualized (data) {
  const tableContainer = document.getElementById('csvTable')
  tableContainer.innerHTML = '' // Clear existing content

  if (!data || data.length === 0) return

  totalRows = data.length

  // Reset last rendered indices
  lastStartIndex = 0
  lastEndIndex = 0

  // Create a scrollable wrapper
  const wrapper = document.createElement('div')
  wrapper.classList.add('uk-overflow-auto') // Add scrollable class
  wrapper.style.maxHeight = '400px' // Adjust as needed
  wrapper.style.position = 'relative' // Make wrapper relative for absolute positioning
  wrapper.style.overflow = 'auto'

  // Create the table header
  const table = document.createElement('div')
  table.classList.add('virtualized-table-header')
  table.style.display = 'flex'
  table.style.position = 'sticky'
  table.style.top = '0'
  table.style.backgroundColor = '#fff'
  table.style.zIndex = '1'
  table.style.borderBottom = '1px solid #ccc'

  data[0].forEach((header, colIndex) => {
    const th = document.createElement('div')
    th.textContent = header.trim()
    th.style.flex = '1'
    th.style.minWidth = '100px' // Minimum width for each column
    th.style.overflow = 'hidden'
    th.style.textOverflow = 'ellipsis'
    th.style.whiteSpace = 'nowrap'
    th.style.padding = '5px'
    th.style.boxSizing = 'border-box'
    th.style.cursor = 'pointer'

    // Add sorting functionality
    th.addEventListener('click', () => sortTableByColumn(colIndex))
    table.appendChild(th)
  })

  wrapper.appendChild(table)

  // Create the spacer div
  const spacer = document.createElement('div')
  spacer.classList.add('spacer')
  spacer.style.height = (totalRows - 1) * rowHeight + 'px' // Adjust for header row
  spacer.style.position = 'relative'

  // Attach scroll event for virtualized rendering
  wrapper.addEventListener('scroll', () => handleViewportScroll(data))

  wrapper.appendChild(spacer)
  tableContainer.appendChild(wrapper)

  // Render initial visible rows
  updateVisibleRows(data)
}

function updateVisibleRows (data) {
  const spacer = document.querySelector('.spacer')
  const visibleContainer = spacer.parentElement
  const scrollTop = visibleContainer.scrollTop
  const visibleHeight = visibleContainer.clientHeight

  visibleStartIndex = Math.max(Math.floor(scrollTop / rowHeight) - buffer, 1) // Skip header row
  visibleEndIndex = Math.min(
    visibleStartIndex + Math.ceil(visibleHeight / rowHeight) + buffer * 2,
    data.length - 1
  )

  // Check if the visible range has changed
  if (visibleStartIndex === lastStartIndex && visibleEndIndex === lastEndIndex) {
    return // No changes, skip re-rendering
  }

  // Remove rows that are no longer visible
  for (let i = lastStartIndex; i <= lastEndIndex; i++) {
    if (i < visibleStartIndex || i > visibleEndIndex) {
      const rowElement = spacer.querySelector(`.row-${i}`)
      if (rowElement) {
        spacer.removeChild(rowElement)
      }
    }
  }

  // Add new visible rows
  for (let i = visibleStartIndex; i <= visibleEndIndex; i++) {
    if (i < lastStartIndex || i > lastEndIndex) {
      const row = data[i]
      const tr = document.createElement('div')
      tr.classList.add(`row-${i}`)
      tr.style.position = 'absolute'
      tr.style.top = (i - 1) * rowHeight + 'px' // Adjust for header row
      tr.style.height = rowHeight + 'px'
      tr.style.display = 'flex'
      tr.style.width = '100%'
      tr.style.boxSizing = 'border-box'
      tr.style.borderBottom = '1px solid #ccc'

      // Add cells for each column in the row
      row.forEach((cell, cellIndex) => {
        const td = document.createElement('div')
        td.textContent = cell.trim()
        td.contentEditable = true // Allow editing
        td.dataset.rowIndex = i
        td.dataset.cellIndex = cellIndex
        td.addEventListener('blur', saveTableData) // Save on edit
        td.style.flex = '1'
        td.style.minWidth = '100px' // Minimum width for each cell
        td.style.padding = '5px'
        td.style.boxSizing = 'border-box'
        td.style.overflow = 'hidden'
        td.style.textOverflow = 'ellipsis'
        td.style.whiteSpace = 'nowrap'
        tr.appendChild(td)
      })

      // Add "add row" and "remove row" buttons in the last cell
      const tdButtons = document.createElement('div')
      tdButtons.style.display = 'flex'
      tdButtons.style.gap = '5px'
      tdButtons.style.padding = '5px'
      tdButtons.style.boxSizing = 'border-box'

      // Add row button
      const addRowBtn = document.createElement('button')
      addRowBtn.textContent = '+'
      addRowBtn.classList.add('add-row-btn')
      addRowBtn.onclick = () => addRowAt(i + 1) // Add row below
      tdButtons.appendChild(addRowBtn)

      // Remove row button
      const removeRowBtn = document.createElement('button')
      removeRowBtn.textContent = '×'
      removeRowBtn.classList.add('remove-row-btn')
      removeRowBtn.onclick = () => removeRowAt(i) // Remove current row
      tdButtons.appendChild(removeRowBtn)

      tr.appendChild(tdButtons)

      spacer.appendChild(tr)
    }
  }

  // Update last rendered range
  lastStartIndex = visibleStartIndex
  lastEndIndex = visibleEndIndex
}

let scrollTimeout
function handleViewportScroll (data) {
  if (scrollTimeout) clearTimeout(scrollTimeout)
  scrollTimeout = setTimeout(() => {
    updateVisibleRows(data)
  }, 50) // Debounce time (ms)
}

function addRowAt (index) {
  const newRow = new Array(csvData[0].length).fill('') // Create an empty row
  csvData.splice(index, 0, newRow) // Insert the new row at the specified index
  totalRows = csvData.length
  displayTableVirtualized(csvData) // Re-render the table
}

function removeRowAt (index) {
  if (index > 0 && index < csvData.length) {
    // Ensure we don't delete the header row
    csvData.splice(index, 1) // Remove the row at the specified index
    totalRows = csvData.length
    displayTableVirtualized(csvData) // Re-render the table
  } else {
    alert('Cannot remove the header row!')
  }
}

function parseCSVFromFile () {
  const files = document.getElementById('csvFile').files
  if (!files.length) {
    alert('Please upload a CSV file!')
    return
  }

  const readers = Array.from(files).map((file) => {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.onload = (e) => resolve(parseCSVContent(e.target.result))
      reader.readAsText(file)
    })
  })

  Promise.all(readers).then((results) => {
    csvData = results.flat()
    displayTableVirtualized(csvData)
  })
}

function parseCSVFromText () {
  const csvText = document.getElementById('csvText').value.trim()
  if (!csvText) {
    alert('Please enter CSV data in the text box!')
    return
  }
  csvData = parseCSVContent(csvText)
  displayTableVirtualized(csvData)
}

function parseCSVContent (content) {
  const delimiter = detectDelimiter(content)
  const rows = content
    .split('\n')
    .map((row) => row.trim())
    .filter((row) => row.length > 0)

  const header = rows[0].split(delimiter)
  const expectedColumns = header.length

  return rows.map((row) => {
    const cells = row.split(delimiter)
    if (cells.length > expectedColumns) {
      const mergedCells = cells.slice(0, expectedColumns - 1)
      mergedCells.push(cells.slice(expectedColumns - 1).join(delimiter))
      return mergedCells
    } else if (cells.length < expectedColumns) {
      while (cells.length < expectedColumns) {
        cells.push('')
      }
    }
    return cells
  })
}

function detectDelimiter (content) {
  const delimiters = [',', ';', '|', '\t']
  const counts = delimiters.map((d) => (content.match(new RegExp(`\\${d}`, 'g')) || []).length)
  return delimiters[counts.indexOf(Math.max(...counts))] || ','
}

function saveTableData (event) {
  const td = event.target
  const rowIndex = parseInt(td.dataset.rowIndex, 10)
  const cellIndex = parseInt(td.dataset.cellIndex, 10)
  const newValue = td.textContent.trim()

  // Update the global csvData array
  csvData[rowIndex][cellIndex] = newValue
}

function exportCSV () {
  const csvContent = csvData.map((row) => row.join(',')).join('\n')
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
  const link = document.createElement('a')
  link.href = URL.createObjectURL(blob)
  link.download = 'processed_data.csv'
  link.click()
}

function sortTableByColumn (colIndex) {
  const isNumeric = csvData[1] && !isNaN(csvData[1][colIndex]) // Check if column is numeric
  const direction = sortDirection[colIndex] === 'asc' ? 'desc' : 'asc' // Toggle direction
  sortDirection[colIndex] = direction // Track sort direction

  const header = csvData[0]
  const rows = csvData.slice(1).filter((row) => row && row.length > 0) // Filter valid rows

  const sortedRows = rows.sort((a, b) => {
    const aValue = a[colIndex] || ''
    const bValue = b[colIndex] || ''
    const compare = isNumeric
      ? parseFloat(aValue) - parseFloat(bValue)
      : aValue.localeCompare(bValue)

    return direction === 'asc' ? compare : -compare // Apply sort direction
  })

  csvData = [header, ...sortedRows]
  displayTableVirtualized(csvData)
  updateSortIndicator(colIndex, direction)
}

function updateSortIndicator (colIndex, direction) {
  const headers = document.querySelectorAll('.virtualized-table-header div')
  headers.forEach((header, index) => {
    header.classList.remove('sort-asc', 'sort-desc')
    if (index === colIndex) {
      header.classList.add(`sort-${direction}`)
    }
  })
}

function saveSession () {
  localStorage.setItem('csvData', JSON.stringify(csvData))
  alert('Session saved!')
}

function loadSession () {
  const savedData = localStorage.getItem('csvData')
  if (savedData) {
    csvData = JSON.parse(savedData)
    displayTableVirtualized(csvData)
    alert('Session loaded!')
  } else {
    alert('No saved session found.')
  }
}
