// Ensure this code runs after the DOM is fully loaded
document.addEventListener('DOMContentLoaded', () => {
  const previewButton = document.getElementById('previewButton')

  if (!previewButton) {
    return
  }

  previewButton.addEventListener('click', fetchOGTags)
})

async function fetchOGTags () {
  const url = document.getElementById('urlInput').value
  const apiKey = 'b639a0d9-a431-48ca-b7d4-3c801c4f80a0' // Replace with your actual API key

  if (!url) {
    alert('Vui lòng nhập một URL hợp lệ.')
    return
  }

  try {
    const response = await fetch(`https://opengraph.io/api/1.1/site/${encodeURIComponent(url)}?app_id=${apiKey}`)
    const data = await response.json()

    if (data.error) {
      throw new Error(data.error.message)
    }

    const ogData = data.hybridGraph

    // Update the preview with the fetched OG tags
    document.getElementById('previewTitle').textContent = ogData.title || 'Không có tiêu đề'
    document.getElementById('previewDescription').textContent = ogData.description || 'Không có mô tả'
    document.getElementById('previewImage').src = ogData.image || ''
    document.getElementById('previewLink').href = url
    document.getElementById('previewLink').textContent = url

    // Show the preview
    document.getElementById('previewContainer').style.display = 'block'
  } catch (error) {
    console.error('Error fetching the OG tags:', error)
    alert('Không thể lấy các thẻ Open Graph. Hãy chắc chắn rằng URL hợp lệ và thử lại.')
  }
}
