import * as XLSX from 'xlsx';

// Function to convert pasted data into an HTML table
function convertPastedData() {
  const inputElement = document.getElementById('dataInput');
  const headersCheckbox = document.getElementById('useHeaders');
  const outputDiv = document.getElementById('output');

  if (!inputElement || !headersCheckbox || !outputDiv) {
    console.error('Required elements are missing from the DOM!');
    return;
  }

  const input = inputElement.value.trim();
  const useHeaders = headersCheckbox.checked;

  if (!input) {
    UIkit.notification({ message: 'Please paste some table data.', status: 'warning' });
    return;
  }

  const rows = input.split('\n');
  let table = '<table class="uk-table uk-table-divider">\n';

  rows.forEach((row, index) => {
    table += '  <tr>\n';
    const cells = row.split('\t');
    cells.forEach((cell) => {
      if (useHeaders && index === 0) {
        table += `    <th>${cell.trim()}</th>\n`;
      } else {
        table += `    <td>${cell.trim()}</td>\n`;
      }
    });
    table += '  </tr>\n';
  });

  table += '</table>';
  displayOutput(table);
}

// Function to convert an uploaded Excel file into an HTML table
function convertExcelFile() {
  const fileInput = document.getElementById('excelFileInput');
  const headersCheckbox = document.getElementById('useHeaders');
  if (!fileInput) {
    console.error('File input element not found!');
    return;
  }

  const file = fileInput.files[0];
  if (!file) {
    UIkit.notification({ message: 'Please select an Excel file.', status: 'warning' });
    return;
  }

  const reader = new FileReader();

  reader.onload = function (e) {
    const data = new Uint8Array(e.target.result);
    const workbook = XLSX.read(data, { type: 'array' });

    if (!workbook.SheetNames.length) {
      console.error('No sheets found in the uploaded Excel file!');
      return;
    }

    const firstSheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[firstSheetName];
    const useHeaders = headersCheckbox.checked; // Check if headers are enabled

    // Convert sheet data to an array of arrays for consistent row processing
    const jsonData = XLSX.utils.sheet_to_json(worksheet, {
      header: 1, // Always return an array of arrays
      defval: '', // Ensure empty cells are represented as empty strings
    });

    // Generate HTML table to match the pasted text behavior
    const tableHtml = generateHtmlTable(jsonData, useHeaders);
    displayOutput(tableHtml);
  };

  reader.readAsArrayBuffer(file);
}


// Function to generate the HTML table
function generateHtmlTable(data, useHeaders) {
  let table = '<table class="uk-table uk-table-divider">\n';

  data.forEach((row, rowIndex) => {
    table += '  <tr>\n';

    if (useHeaders && rowIndex === 0) {
      // First row as header (only for arrays when headers are enabled)
      if (Array.isArray(row)) {
        row.forEach((cell) => {
          table += `    <th>${cell || ''}</th>\n`;
        });
      }
    } else {
      // Handle data rows (array or object)
      if (Array.isArray(row)) {
        // If row is an array (e.g., when headers are enabled)
        row.forEach((cell) => {
          table += `    <td>${cell || ''}</td>\n`;
        });
      } else if (typeof row === 'object') {
        // If row is an object (e.g., when headers are disabled)
        Object.values(row).forEach((cell) => {
          table += `    <td>${cell || ''}</td>\n`;
        });
      }
    }

    table += '  </tr>\n';
  });

  table += '</table>';
  return table;
}

// Function to display the generated HTML table
function displayOutput(htmlContent) {
  const outputDiv = document.getElementById('output');
  if (!outputDiv) {
    console.error('Output div not found!');
    return;
  }

  // Update the output div with the table and preview
  outputDiv.innerHTML = `
    <h3 class="uk-heading-line uk-margin-top uk-margin-bottom"><span>HTML Code:</span></h3>
    <pre class="uk-overflow-auto"><code>${escapeHtml(htmlContent)}</code></pre>
    <h3 class="uk-heading-line uk-margin-top uk-margin-bottom"><span>Preview Table:</span></h3>
    ${htmlContent}
  `;
}

// Function to escape HTML characters for safe rendering
function escapeHtml(text) {
  return text.replace(/[&<>"']/g, (match) => {
    const escapeChars = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
      "'": '&#39;',
    };
    return escapeChars[match];
  });
}

// Function to download the HTML table as a file
function downloadHTML() {
  const outputDiv = document.getElementById('output');
  const table = outputDiv?.querySelector('table'); // Select the table in the output div

  if (!table) {
    UIkit.notification({ message: 'No table to download.', status: 'danger' });
    return;
  }

  const htmlContent = table.outerHTML; // Get the outer HTML of the table
  const blob = new Blob([htmlContent], { type: 'text/html' });
  const url = URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  a.download = 'table.html'; // File name for the download
  a.click();
  URL.revokeObjectURL(url);
}

// Function to download the table as a CSV file
function downloadCSV() {
  const outputDiv = document.getElementById('output');
  const table = outputDiv?.querySelector('table');
  if (!table) {
    UIkit.notification({ message: 'No table to download.', status: 'danger' });
    return;
  }

  const rows = Array.from(table.rows);
  const csvContent = rows
    .map((row) =>
      Array.from(row.cells)
        .map((cell) => `"${cell.textContent}"`)
        .join(',')
    )
    .join('\n');

  const blob = new Blob([csvContent], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'table.csv';
  a.click();
  URL.revokeObjectURL(url);
}

// Function to copy HTML code to clipboard
function copyToClipboard() {
  const outputDiv = document.getElementById('output');
  const codeBlock = outputDiv?.querySelector('pre code');
  if (!codeBlock) {
    UIkit.notification({ message: 'No HTML code to copy.', status: 'danger' });
    return;
  }

  const tempTextarea = document.createElement('textarea');
  tempTextarea.value = codeBlock.textContent;
  document.body.appendChild(tempTextarea);
  tempTextarea.select();
  document.execCommand('copy');
  document.body.removeChild(tempTextarea);

  UIkit.notification({ message: 'HTML code copied to clipboard.', status: 'success' });
}

// Safe Event Listener Binding
function safeEventListener(id, event, handler) {
  const element = document.getElementById(id);
  if (element) {
    element.addEventListener(event, handler);
  } else {
    console.error(`Element with id '${id}' not found!`);
  }
}

// Attach event listeners safely
document.addEventListener('DOMContentLoaded', () => {
  safeEventListener('dataInput', 'input', convertPastedData);
  safeEventListener('excelFileInput', 'change', convertExcelFile);
  safeEventListener('download-table', 'click', downloadHTML);
  safeEventListener('download-csv', 'click', downloadCSV);
  safeEventListener('copy-table-clipboard', 'click', copyToClipboard);
  safeEventListener('useHeaders', 'change', () => {
    const fileInput = document.getElementById('excelFileInput');
    const dataInput = document.getElementById('dataInput');
    if (fileInput.files.length > 0) {
      convertExcelFile();
    } else if (dataInput.value.trim()) {
      convertPastedData();
    }
  });
});
