const allRanges = document.querySelectorAll('.container-all-complexity-range')

allRanges.forEach((wrap) => {
  const range = wrap.querySelector('.password-range')
  const bubble = wrap.querySelector('.bubble')

  range.addEventListener('input', () => {
    if (range) {
      setBubble(range, bubble)
    }
  })

  if (range) {
    setBubble(range, bubble)
  }

  if (!range || !bubble) {
    return
  }

  range.addEventListener('input', () => {
    setBubble(range, bubble)
  })
  setBubble(range, bubble)
})

export function setBubble (range, bubble) {
  if (!range) {
    return
  }

  const val = range.value || 0
  const min = range.min ? range.min : 0
  const max = range.max ? range.max : 100
  const newVal = Number(((val - min) * 100) / (max - min))
  let status

  switch (newVal) {
    case 0:
      status = 'Chỉ chứa ký tự chữ thường (ab...) trừ i, l, o'
      break
    case 20:
      status = 'Bao gồm các ký tự trước đó, cộng thêm số (23...) trừ "0", "1"'
      break
    case 40:
      status = 'Bao gồm các ký tự trước đó, cộng thêm chữ hoa (AB...), trừ "O"'
      break
    case 60:
      status = 'Bao gồm các ký tự trước đó, cộng thêm các ký tự dễ nhầm lẫn: il1o0O'
      break
    case 80:
      status = 'Bao gồm các ký tự trước đó, cộng thêm ký hiệu không dễ nhầm lẫn: @#$%&*+=?'
      break
    case 100:
      status = 'Bao gồm các ký tự trước đó, cộng thêm các ký hiệu dễ gây nhầm lẫn cho mắt: `~^()<>-_!|\\/,.'
      break
    default:
      status = 'Không xác định'
  }

  bubble.innerHTML = status

  // Sorta magic numbers based on size of the native UI thumb
  bubble.style.left = `calc(${newVal}% + (${8.5 - newVal * 0.3}px))`
}
