document.addEventListener('DOMContentLoaded', () => {
  // Select elements
  const textarea = document.getElementById('paragraph-analyze')
  const wordCountElement = document.getElementById('word-count')
  const charCountElement = document.getElementById('characters-count')

  // Check if all elements exist
  if (!textarea || !wordCountElement || !charCountElement) {
    return
  }

  // Function to count words
  function countWords (text) {
    if (!text.trim()) return 0
    return text.trim().split(/\s+/).length // Split by whitespace and count
  }

  // Function to count characters
  function countCharacters (text) {
    return text.length // Return total length of the string
  }

  // Event listener for textarea input
  textarea.addEventListener('input', () => {
    const text = textarea.value

    // Update word and character counts
    const wordCount = countWords(text)
    const charCount = countCharacters(text)

    wordCountElement.textContent = `Words: ${wordCount}`
    charCountElement.textContent = `Characters: ${charCount}`
  })
})
