function copyToClipboard () {
  /* Get the text field */
  var copyText = document.getElementById('password')

  if (navigator.clipboard && navigator.clipboard.writeText) {
    // Use Clipboard API if supported
    navigator.clipboard.writeText(copyText.value)
      .then(() => {
        console.log('Copied to clipboard: ' + copyText.value)
      })
      .catch((err) => {
        console.error('Error copying to clipboard: ', err)
      })
  } else {
    // Fallback for browsers that do not support Clipboard API
    copyText.select()
    copyText.setSelectionRange(0, 99999) /* For mobile devices */

    try {
      const successful = document.execCommand('copy')
      if (successful) {
        console.log('Fallback: Copied to clipboard')
      } else {
        console.error('Fallback: Copy command failed')
      }
    } catch (err) {
      console.error('Fallback: Unable to copy', err)
    }
  }
}

/*
 * menu-trigger btn
 * ----------------------------------------------- */
jQuery(function ($) {
  $('#password').on('click', function () {
    $(this).select()
    copyToClipboard()
  })
})
