const allRanges = document.querySelectorAll('.character-range')
allRanges.forEach((wrap) => {
  const range = wrap.querySelector('.password-range')
  const bubble = wrap.querySelector('.bubble')

  range.addEventListener('input', () => {
    setBubble(range, bubble)
  })
  setBubble(range, bubble)
})

function setBubble (range, bubble) {
  const val = range.value
  const min = range.min ? range.min : 0
  const max = range.max ? range.max : 100
  const newVal = Number(((val - min) * 100) / (max - min))
  bubble.innerHTML = val + ' chars'

  // Sorta magic numbers based on size of the native UI thumb
  bubble.style.left = `calc(${newVal}% + (${8.5 - newVal * 0.9}px))`
}
