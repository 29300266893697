import namer from 'color-namer';
(function () {
  // Get elements
  const hexValue = document.getElementById('hex-value')
  const rgbValue = document.getElementById('rgb-value')
  const hslValue = document.getElementById('hsl-value')
  const hsvValue = document.getElementById('hsv-value')
  const cmykValue = document.getElementById('cmyk-value')
  const luminanceValue = document.getElementById('luminance-value')
  const brightnessValue = document.getElementById('brightness-value')
  const labValue = document.getElementById('lab-value')
  const xyzValue = document.getElementById('xyz-value')
  const warmColdValue = document.getElementById('warm-cold-value')
  const colorName = document.getElementById('color-name')
  const complementaryValue = document.getElementById('complementary-value')
  const colorPicker = document.getElementById('color-picker')
  const colorInput = document.getElementById('color-input')
  const colorPreview = document.getElementById('color-preview')
  const colorCode = document.getElementById('color-code')
  // Ensure all elements exist in the DOM
  if (!colorPicker || !colorInput || !colorPreview || !colorCode) {
    return
  }

  // Convert HEX to HSL
  function hexToHsl (hex) {
    // Remove the # if present
    hex = hex.replace('#', '')

    // Convert hex to RGB
    const r = parseInt(hex.substring(0, 2), 16) / 255
    const g = parseInt(hex.substring(2, 4), 16) / 255
    const b = parseInt(hex.substring(4, 6), 16) / 255

    const max = Math.max(r, g, b)
    const min = Math.min(r, g, b)
    let h; let s; const l = (max + min) / 2

    if (max === min) {
      h = s = 0 // achromatic
    } else {
      const d = max - min
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
      switch (max) {
        case r: h = (g - b) / d + (g < b ? 6 : 0); break
        case g: h = (b - r) / d + 2; break
        case b: h = (r - g) / d + 4; break
      }
      h /= 6
    }

    return `${Math.round(h * 360)}°, ${Math.round(s * 100)}%, ${Math.round(l * 100)}%`
  }

  // Convert HEX to HSV
  function hexToHsv (hex) {
    // Remove the # if present
    hex = hex.replace('#', '')

    const r = parseInt(hex.substring(0, 2), 16) / 255
    const g = parseInt(hex.substring(2, 4), 16) / 255
    const b = parseInt(hex.substring(4, 6), 16) / 255

    const max = Math.max(r, g, b)
    const min = Math.min(r, g, b)
    let h; let s; const v = max

    const d = max - min
    s = max === 0 ? 0 : d / max

    if (max === min) {
      h = 0
    } else {
      switch (max) {
        case r: h = (g - b) / d + (g < b ? 6 : 0); break
        case g: h = (b - r) / d + 2; break
        case b: h = (r - g) / d + 4; break
      }
      h /= 6
    }

    return `${Math.round(h * 360)}°, ${Math.round(s * 100)}%, ${Math.round(v * 100)}%`
  }

  // Convert RGB to CMYK
  function rgbToCmyk (rgb) {
    const [r, g, b] = rgb.split(',').map(x => parseInt(x.trim()) / 255)

    const k = 1 - Math.max(r, g, b)
    const c = k === 1 ? 0 : (1 - r - k) / (1 - k)
    const m = k === 1 ? 0 : (1 - g - k) / (1 - k)
    const y = k === 1 ? 0 : (1 - b - k) / (1 - k)

    return `${Math.round(c * 100)}%, ${Math.round(m * 100)}%, ${Math.round(y * 100)}%, ${Math.round(k * 100)}%`
  }

  // Calculate Luminance
  function calculateLuminance (rgb) {
    const [r, g, b] = rgb.split(',').map(x => {
      x = parseInt(x.trim()) / 255
      return x <= 0.03928 ? x / 12.92 : Math.pow((x + 0.055) / 1.055, 2.4)
    })

    return Math.round((0.2126 * r + 0.7152 * g + 0.0722 * b) * 100)
  }

  // Calculate Brightness
  function calculateBrightness (rgb) {
    const [r, g, b] = rgb.split(',').map(x => parseInt(x.trim()))
    return Math.round(Math.sqrt(
      0.299 * r * r +
      0.587 * g * g +
      0.114 * b * b
    ))
  }

  // Convert RGB to Lab
  function rgbToLab (rgb) {
    let [r, g, b] = rgb.split(',').map(x => parseInt(x.trim()) / 255)

    // Convert RGB to XYZ
    r = r > 0.04045 ? Math.pow(((r + 0.055) / 1.055), 2.4) : r / 12.92
    g = g > 0.04045 ? Math.pow(((g + 0.055) / 1.055), 2.4) : g / 12.92
    b = b > 0.04045 ? Math.pow(((b + 0.055) / 1.055), 2.4) : b / 12.92

    r *= 100
    g *= 100
    b *= 100

    let x = r * 0.4124 + g * 0.3576 + b * 0.1805
    let y = r * 0.2126 + g * 0.7152 + b * 0.0722
    let z = r * 0.0193 + g * 0.1192 + b * 0.9505

    // Convert XYZ to Lab
    x /= 95.047
    y /= 100.000
    z /= 108.883

    x = x > 0.008856 ? Math.pow(x, 1 / 3) : (7.787 * x) + 16 / 116
    y = y > 0.008856 ? Math.pow(y, 1 / 3) : (7.787 * y) + 16 / 116
    z = z > 0.008856 ? Math.pow(z, 1 / 3) : (7.787 * z) + 16 / 116

    const L = (116 * y) - 16
    const a = 500 * (x - y)
    const labB = 200 * (y - z)

    return `L: ${Math.round(L)}, a: ${Math.round(a)}, b: ${Math.round(labB)}`
  }

  // Convert RGB to XYZ
  function rgbToXyz (rgb) {
    let [r, g, b] = rgb.split(',').map(x => parseInt(x.trim()) / 255)

    r = r > 0.04045 ? Math.pow(((r + 0.055) / 1.055), 2.4) : r / 12.92
    g = g > 0.04045 ? Math.pow(((g + 0.055) / 1.055), 2.4) : g / 12.92
    b = b > 0.04045 ? Math.pow(((b + 0.055) / 1.055), 2.4) : b / 12.92

    r *= 100
    g *= 100
    b *= 100

    const x = r * 0.4124 + g * 0.3576 + b * 0.1805
    const y = r * 0.2126 + g * 0.7152 + b * 0.0722
    const z = r * 0.0193 + g * 0.1192 + b * 0.9505

    return `${x.toFixed(1)}, ${y.toFixed(1)}, ${z.toFixed(1)}`
  }

  // Determine if color is warm or cold
  function getWarmCold (hex) {
    hex = hex.replace('#', '')

    const r = parseInt(hex.substring(0, 2), 16)
    const g = parseInt(hex.substring(2, 4), 16)
    const b = parseInt(hex.substring(4, 6), 16)

    // Colors are considered warm if red and yellow components are dominant
    if (r > b) {
      if (r > g) {
        return 'Warm'
      }
      if (g > r && g > b) {
        return r > b ? 'Warm' : 'Cold'
      }
    }

    // Colors are considered cold if blue component is dominant
    if (b > r && b > g) {
      return 'Cold'
    }

    // If the differences are minimal, consider it neutral
    if (Math.abs(r - b) < 15 && Math.abs(r - g) < 15 && Math.abs(b - g) < 15) {
      return 'Neutral'
    }

    return r > b ? 'Warm' : 'Cold'
  }

  // Function to get color name using color-namer
  function getColorName (hex) {
    try {
      const names = namer(hex)
      return names.ntc[0].name // Get the closest match using the "ntc" palette
    } catch (error) {
      console.error('Error in getColorName:', error)
      return 'Unknown'
    }
  }

  function getComplementaryColor (hex) {
    // Remove the # if present
    hex = hex.replace('#', '')

    // Convert to decimal and get the complement
    const rgb = parseInt(hex, 16)
    const complement = 0xFFFFFF ^ rgb

    // Convert back to hex
    const complementHex = '#' + complement.toString(16).padStart(6, '0')
    return complementHex
  }

  // Convert HEX to RGB
  function hexToRgb (hex) {
    const bigint = parseInt(hex.slice(1), 16)
    const r = (bigint >> 16) & 255
    const g = (bigint >> 8) & 255
    const b = bigint & 255
    return `${r}, ${g}, ${b}`
  }

  // Event listeners for color picker and input
  colorPicker.addEventListener('input', (e) => {
    const color = e.target.value
    updateColorInformation(color)
  })

  colorInput.addEventListener('input', (e) => {
    const color = e.target.value
    if (/^#[0-9A-F]{6}$/i.test(color)) {
      updateColorInformation(color)
    }
  })

  // Get additional elements for the CSS section
  const cssExamples = document.getElementById('css-examples')

  // Function to update CSS Examples
  function updateCssExamples (color) {
    const rgb = hexToRgb(color)
    const hsl = hexToHsl(color)

    cssExamples.textContent = `
/* Mã màu HEX */
background-color: ${color}; /* Màu nền theo mã HEX */

/* Mã màu RGB */
background-color: rgb(${rgb}); /* Màu nền theo hệ RGB */

/* Mã màu HSL */
background-color: hsl(${hsl}); /* Màu nền theo hệ HSL */

/* HSV (tương tự HSL, CSS không hỗ trợ HSV) */
background-color: hsl(${hsl}); /* Sử dụng giá trị HSL, tương tự HSV */

/* CMYK (CSS không hỗ trợ) */

/* Độ sáng Luminance (CSS không hỗ trợ) */

/* Độ sáng Brightness (CSS không hỗ trợ) */

/* Màu Lab (yêu cầu công cụ nâng cao như PostCSS hoặc Sass) */

/* Màu XYZ (CSS không hỗ trợ) */
    `.trim()
  }

  // Function to update color information
  function updateColorInformation (color) {
    try {
      // Basic color values
      hexValue.textContent = color
      rgbValue.textContent = hexToRgb(color)

      // Color space conversions
      hslValue.textContent = hexToHsl(color)
      hsvValue.textContent = hexToHsv(color)
      cmykValue.textContent = rgbToCmyk(hexToRgb(color))

      // Color properties
      luminanceValue.textContent = `${calculateLuminance(hexToRgb(color))}%`
      brightnessValue.textContent = calculateBrightness(hexToRgb(color))
      labValue.textContent = rgbToLab(hexToRgb(color))
      xyzValue.textContent = rgbToXyz(hexToRgb(color))

      // Color characteristics
      colorName.textContent = getColorName(color)
      warmColdValue.textContent = getWarmCold(color)
      complementaryValue.textContent = getComplementaryColor(color)

      // Update visual elements
      colorInput.value = color
      colorPicker.value = color
      colorPreview.style.backgroundColor = color
      colorCode.textContent = color

      // Update CSS Examples
      updateCssExamples(color)
    } catch (error) {
      console.error('Error in updateColorInformation:', error)
    }
  }

  // Update CSS Examples function
  function updateCssExamples (color) {
    const rgb = hexToRgb(color)
    const hsl = hexToHsl(color)

    cssExamples.textContent = `
/* Mã màu HEX */
background-color: ${color}; /* Màu nền theo mã HEX */

/* Mã màu RGB */
background-color: rgb(${rgb}); /* Màu nền theo hệ RGB */

/* Mã màu HSL */
background-color: hsl(${hsl}); /* Màu nền theo hệ HSL */

/* HSV (tương tự HSL, CSS không hỗ trợ HSV) */
background-color: hsl(${hsl}); /* Sử dụng giá trị HSL, tương tự HSV */

/* CMYK (CSS không hỗ trợ) */
/* ${rgbToCmyk(rgb)} */

/* Độ sáng Luminance */
/* ${calculateLuminance(rgb)}% */

/* Độ sáng Brightness */
/* ${calculateBrightness(rgb)} */

/* Màu Lab */
/* ${rgbToLab(rgb)} */

/* Màu XYZ */
/* ${rgbToXyz(rgb)} */
    `.trim()
  }

  // Event listeners
  colorPicker.addEventListener('input', (e) => {
    const color = e.target.value
    updateColorInformation(color)
  })

  colorInput.addEventListener('input', (e) => {
    const color = e.target.value
    if (/^#[0-9A-F]{6}$/i.test(color)) {
      updateColorInformation(color)
    }
  })

  // Initial color update (optional)
  updateColorInformation('#cccccc')
})()
