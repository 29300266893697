document.addEventListener('DOMContentLoaded', () => {
  const generateButton = document.querySelector('#tag-generate')
  const resetButton = document.querySelector("button[type='reset']")

  function addHttpsIfMissing (url) {
    if (url && !url.startsWith('http://') && !url.startsWith('https://')) {
      return `https://${url}`
    }
    return url
  }

  if (generateButton) {
    generateButton.addEventListener('click', () => {
      const titleElement = document.getElementById('title')
      const descriptionElement = document.getElementById('description')
      const authorElement = document.getElementById('author')
      const websiteUrlElement = document.getElementById('website-url')
      const imgUrlElement = document.getElementById('img-url')

      // Check if all elements exist
      if (!titleElement || !descriptionElement || !authorElement || !websiteUrlElement || !imgUrlElement) {
        return
      }

      const title = titleElement.value.trim()
      const description = descriptionElement.value.trim()
      const author = authorElement.value.trim()
      let websiteUrl = websiteUrlElement.value.trim()
      let imgUrl = imgUrlElement.value.trim()

      // Automatically add https:// if missing
      websiteUrl = addHttpsIfMissing(websiteUrl)
      imgUrl = addHttpsIfMissing(imgUrl)

      // Update input fields with corrected URLs
      websiteUrlElement.value = websiteUrl
      imgUrlElement.value = imgUrl

      if (!title || !description || !author || !websiteUrl || !imgUrl) {
        alert('Vui lòng điền đầy đủ tất cả các trường.')
        return
      }

      const metaTags = `
<meta name="title" content="${title}">
<meta name="description" content="${description}">
<meta name="author" content="${author}">
<meta name="url" content="${websiteUrl}">
<meta property="og:title" content="${title}">
<meta property="og:description" content="${description}">
<meta property="og:image" content="${imgUrl}">
<meta property="og:author" content="${author}">
<meta property="og:type" content="website">
<meta property="og:url" content="${websiteUrl}">
      `

      const output = document.createElement('pre')
      output.className = 'uk-margin'
      output.style.background = '#f8f9fa'
      output.style.padding = '10px'
      output.style.borderRadius = '4px'
      output.textContent = metaTags

      // Append output to the card body if not already present
      const cardBody = document.querySelector('.uk-card-body')
      const existingOutput = cardBody.querySelector('pre')
      if (existingOutput) {
        existingOutput.textContent = metaTags
      } else {
        cardBody.appendChild(output)
      }
    })
  }

  if (resetButton) {
    resetButton.addEventListener('click', () => {
      const output = document.querySelector('.uk-card-body pre')
      if (output) {
        output.remove()
      }
    })
  }
})
