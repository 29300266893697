export const MAX_SAFE_INTEGER = 9007199254740991 // 2^53 - 1 or 2**53-1 (ES6 syntax)
export const MIN_SAFE_INTEGER = -9007199254740991 // -(2^53 - 1) or -(2**53 - 1) (ES6 syntax)
export const MIN_INT32 = -2147483648
export const MAX_INT32 = 2147483647
export const MIN_UINT32 = 0
export const MAX_UINT32 = 4294967295
export const MAX_BITS_PER_NUMBER = 32 // we limit every operations to be within this amount of bits as all JS bitwise operations only operate on or produce 32 bits numbers
export const MAX_CARDINALITY = MAX_UINT32 + 1
export const MIN_CARDINALITY = 2
// NOTICE:
// + JS bitwise operations uses only 32 least-significant bits, see: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Bitwise_AND#description
// Returns minimum number of bits needed to exactly represent "cardinality" number of unique items.
export function bitsToMapItem (cardinality) {
  // ref: https://math.stackexchange.com/questions/160295/how-many-bits-needed-to-store-a-number
  if (cardinality <= 0) {
    return 0
  }
  return Math.ceil(Math.log2(cardinality))
}
// round cardinality to nearest greater number aligned to the following series: 2^1, 2^2, 2^3, 2^4, 2^5, 2^6, ... 2^32
export function binaryAlign (cardinality) {
  if (cardinality <= 0) {
    return 0
  }
  return Math.pow(2, bitsToMapItem(cardinality))
}
// BEWARE:
// Numbers: |xxxxxxxxx|xxxxxxxxx|xxxxxxxxx| => bitsPerNum=9
// Series:  |ooo|ooo|ooo|ooo|ooo|ooo| => bitsPerItem=3
// numbersToHoldSeries(4) == 2
// numbersToHoldSeries(5) == 2
// numbersToHoldSeries(6) == 2
// seriesItemsFromNumbers(2) == 6
// seriesItemsFromNumbers(numbersToHoldSeries(4)) != 4
export function numbersToHoldSeries (
  seriesLength,
  seriesCardinality = MIN_CARDINALITY,
  bitsPerNumber = MAX_BITS_PER_NUMBER
) {
  return Math.ceil(
    (bitsToMapItem(seriesCardinality) * seriesLength) / bitsPerNumber
  )
}
export function seriesItemsFromNumbers (
  numbersLength,
  seriesCardinality = MIN_CARDINALITY,
  bitsPerNumber = MAX_BITS_PER_NUMBER
) {
  return Math.floor(
    (numbersLength * bitsPerNumber) / bitsToMapItem(seriesCardinality)
  )
}
export function toUint32 (n) {
  // ref: https://stackoverflow.com/a/16155417
  return n >>> 0
}
// Low means least-significant bits
export function genLowOnes32Mask (n) {
  if (n <= 0) {
    return 0
  }
  if (n >= MAX_BITS_PER_NUMBER) {
    return MAX_UINT32
  }
  return toUint32(Math.pow(2, n) - 1)
}
// High means most-significant bits
export function genHighOnes32Mask (n) {
  return ~genLowOnes32Mask(MAX_BITS_PER_NUMBER - n) >>> 0
}
// lowIndex (inclusive): 0-based index of least-significant bit
// highIndex (exclusive): 0-based index of most-significant bit
// extract from lowIndex up to but not including highIndex
export function bitsSlice32 (n, lowIndex, highIndex = MAX_BITS_PER_NUMBER) {
  if (lowIndex < 0 || lowIndex > highIndex || highIndex > MAX_BITS_PER_NUMBER) {
    throw new RangeError(
      'index range must satisfy 0 <= lowIndex <= highIndex <= MAX_BITS_PER_NUMBER'
    )
  }
  const mask = genLowOnes32Mask(highIndex)
  return (n & mask) >>> lowIndex
}
export function isASCIIEntirely (s) {
  for (let i = 0; i < s.length; i++) {
    if (s.charCodeAt(i) > 0xff) {
      return false
    }
  }
  return true
}
// convert JS's UTF16 (2-bytes) encoded string to extended-ASCII (1-byte) encoded string
export function UTF16toASCII (s) {
  const low8Mask = genLowOnes32Mask(8)
  let result = ''
  for (let i = 0; i < s.length; i++) {
    const charCode = s.charCodeAt(i)
    // split 2-bytes charCode into 2, each with 1-byte value (high and low portions).
    const mostSignificantPortion = charCode >>> 8
    const leastSignificantPortion = charCode & low8Mask
    result += String.fromCharCode(
      mostSignificantPortion,
      leastSignificantPortion
    )
  }
  return result
}
// revert extended-ASCII (1-byte) encoded string back to JS's UTF16 (2-bytes) encoded string
export function UTF16fromASCII (s) {
  if (s.length % 2 != 0) {
    // length is not even
    throw new RangeError('string length must be a multiple of 2: ' + s.length)
  }
  if (!isASCIIEntirely(s)) {
    throw new RangeError('string must consist entirely of ASCII characters')
  }
  let result = ''
  for (let i = 0; i < s.length; i += 2) {
    const mostSignificantPortion = s.charCodeAt(i)
    const leastSignificantPortion = s.charCodeAt(i + 1)
    const charCode = (mostSignificantPortion << 8) | leastSignificantPortion
    result += String.fromCharCode(charCode)
  }
  return result
}
export function toBase64 (s) {
  if (isASCIIEntirely(s)) {
    return btoa(s)
  }
  // assume UTF16 as JS's builtin String has, quoted: "In JavaScript strings are represented using the UTF-16 character encoding: in this encoding, strings are represented as a sequence of 16-bit (2 byte) units. Every ASCII character fits into the first byte of one of these units, but many other characters don't.". Source: https://developer.mozilla.org/en-US/docs/Web/API/btoa#unicode_strings
  const sASCIIEnc = UTF16toASCII(s)
  return btoa(sASCIIEnc)
}
export function ASCIIfromBase64 (b64s) {
  return atob(b64s)
}
export function UTF16fromBase64 (b64s) {
  const sASCIIEnc = atob(b64s)
  if (sASCIIEnc.length % 2 != 0) {
    throw new RangeError(
      'UTF16-encoded-as-ASCII string length must be a multiple of 2 (even)'
    )
  }
  return UTF16fromASCII(sASCIIEnc)
}
// Series hold an append-only series of numbers, with only 32 least-significant bits used per item even-though they are all 64bits numbers.
export class Series32 {
  constructor (cardinality, rawBase = []) {
    Series32.validateCardinality(cardinality)
    this.crd = cardinality
    this.srs = rawBase.slice(0)
  }

  static validateCardinality (cardinality) {
    if (MIN_CARDINALITY > cardinality || cardinality > MAX_CARDINALITY) {
      throw new RangeError(
        'cardinality must be within [' +
          MIN_CARDINALITY +
          ', ' +
          MAX_CARDINALITY +
          ']'
      )
    }
  }

  // return mininum bits needed. Remaining representable values will go to waste if cardinality doesn't align to binary stepping, such as 2^1, 2^2, 2^3, 2^4, 2^5, 2^6, ... 2^32
  get cardinalityBits () {
    return bitsToMapItem(this.crd)
  }

  get cardinality () {
    return this.crd
  }

  get length () {
    return seriesItemsFromNumbers(
      this.srs.length,
      this.crd,
      MAX_BITS_PER_NUMBER
    )
  }

  get rawBase () {
    return this.srs.slice(0)
  }

  // implication: the lengthOfRawBase returned can be used to make a series with series.length >= seriesLength, depending on alignment. See BEWARE of numbersToHoldSeries()
  calcLengthOfRawBaseToMakeAtLeast (seriesLength) {
    return numbersToHoldSeries(seriesLength, this.crd, MAX_BITS_PER_NUMBER)
  }

  append (item) {
    this.srs.push(item)
  }

  withModulo (n) {
    return n % this.cardinality
  }

  // return 0 <= num < this.cardinality
  * [Symbol.iterator] () {
    const bitsPerItem = this.cardinalityBits
    if (bitsPerItem == MAX_BITS_PER_NUMBER) {
      for (const n of this.srs) {
        yield this.withModulo(toUint32(n))
      }
      return
    }
    let carriedOverValue = 0
    let carriedOverBits = 0
    for (let value of this.srs) {
      let bitsRemain = MAX_BITS_PER_NUMBER
      if (carriedOverBits > 0) {
        const bitsMissing = bitsPerItem - carriedOverBits
        bitsRemain -= bitsMissing
        // shift left to leave room on low-end to add missing bits to complete an item
        carriedOverValue <<= bitsMissing
        // bitwise-or to concat the missing bits
        yield this.withModulo(
          carriedOverValue | bitsSlice32(value, bitsRemain)
        )
        value = bitsSlice32(value, 0, bitsRemain)
        carriedOverBits = 0 // mark that we used up all carry-over bits from previous iteration
      }
      while (bitsRemain >= bitsPerItem) {
        bitsRemain -= bitsPerItem
        yield this.withModulo(bitsSlice32(value, bitsRemain))
        value = bitsSlice32(value, 0, bitsRemain)
      }
      carriedOverValue = value
      carriedOverBits = bitsRemain
    }
  }

  * iterateAtMost (lengthLimit = this.length) {
    let i = 0
    for (const item of this) {
      if (i >= lengthLimit) {
        return
      }
      yield item
      i++
    }
  }

  getAt (i) {
    if (i < 0 || i >= this.length) {
      throw new RangeError(
        'index range must satisfy 0 <= i <= Series32.length'
      )
    }
    const cardinalityBits = this.cardinalityBits
    const prevItems = i
    const prevItemBits = prevItems * cardinalityBits
    const prevNums = Math.floor(prevItemBits / MAX_BITS_PER_NUMBER)
    const prevNumBits = prevNums * MAX_BITS_PER_NUMBER
    const skippedBits = prevItemBits - prevNumBits
    const bitsRemains = MAX_BITS_PER_NUMBER - skippedBits
    let numRemains = bitsSlice32(this.srs[prevNums], 0, bitsRemains)
    if (bitsRemains >= cardinalityBits) {
      return this.withModulo(numRemains >>> (bitsRemains - cardinalityBits))
    }
    const bitsMissing = cardinalityBits - bitsRemains
    const numNeeds = bitsSlice32(
      this.srs[prevNums + 1],
      MAX_BITS_PER_NUMBER - bitsMissing
    )
    numRemains <<= bitsMissing
    return this.withModulo(numRemains | numNeeds)
  }
}
export function getSeries32RNG () {
  try {
    return getCryptoRNG()
  } finally {
    return getMathRNG()
  }
}
// ============================================================================
export class MathRNG {
  static randomWithinSafeInteger () {
    return Math.random() * MAX_SAFE_INTEGER
  }

  /**
   * genSeries32
   */
  genSeries32 (minLength, cardinality = MIN_CARDINALITY) {
    const series = new Series32(cardinality)
    const numbersNeeded = series.calcLengthOfRawBaseToMakeAtLeast(minLength)
    for (let i = 0; i < numbersNeeded; i++) {
      series.append(MathRNG.randomWithinSafeInteger())
    }
    return series
  }
}
const MathRNGSingleton = new MathRNG()
export function getMathRNG () {
  return MathRNGSingleton
}
// ============================================================================
export class CryptoRNG {
  static randomUint32s (count) {
    return crypto.getRandomValues(new Uint32Array(count))
  }

  /**
   * genSeries32
   */
  genSeries32 (minLength, cardinality = MIN_CARDINALITY) {
    const series = new Series32(cardinality)
    for (const u32 of CryptoRNG.randomUint32s(
      series.calcLengthOfRawBaseToMakeAtLeast(minLength)
    )) {
      series.append(u32)
    }
    return series
  }
}
const CryptoRNGSingleton = new CryptoRNG()
export function getCryptoRNG () {
  if (crypto && typeof crypto.getRandomValues === 'function' && Uint32Array) {
    return CryptoRNGSingleton
  }
  throw new TypeError(
    'CryptoRNG is NOT supported under current runtime environment!'
  )
}
export class CharsetLimitedRSG {
  constructor (charSet, rng = getSeries32RNG()) {
    this.rng = rng
    if (!Array.isArray(charSet) || charSet.length === 0) {
      charSet = ['a', 'b', 'c'] // Default non-empty array
    }
    if (typeof charSet === 'string') {
      charSet = charSet.normalize() // we support from ASCII to Unicode so we need to normalize the form to have a unified view on the characters available
      this.charSet = Array.from(charSet) // needed to access individual Unicode full-fledge characters correctly. For more: https://dmitripavlutin.com/what-every-javascript-developer-should-know-about-unicode/
    } else {
      this.charSet = charSet
        .map((char) => char.normalize())
        .filter((char) => Array.from(char).length == 1)
    }
    // deliberately not transforming charSet into distinct set of characters (uniqueness) to allow consumer of this class to customize propability-weight on characters by having them exists multiple times (duplicates) as needed.
    if (this.charSet.length < MIN_CARDINALITY) {
      throw new Error(
        'normalized charSet Unicode-length cannot be under ' + MIN_CARDINALITY
      )
    }
    if (this.charSet.length > MAX_CARDINALITY) {
      throw new Error(
        'normalized charSet Unicode-length cannot exceed ' + MAX_CARDINALITY
      )
    }
  }

  get charSetArray () {
    return Array.from(this.charSet)
  }

  charSetString (delim = '') {
    return this.charSet.join(delim)
  }

  genString (len) {
    const series = this.rng.genSeries32(len, this.charSet.length)
    const chars = []
    for (const charIdx of series.iterateAtMost(len)) {
      chars.push(this.charSet[charIdx])
    }
    return chars.join('')
  }

  genBase64 (origStrLen) {
    return toBase64(this.genString(origStrLen))
  }
}
export const ASCII_LEVELS_CHARSETS = [
  'abcdefghjkmnpqrstuvwxyz',
  '23456789',
  'ABCDEFGHIJKLMNPQRSTUVWXYZ',
  'ilo01O',
  '@#$%&*+=?',
  '`~^()<>-_!|\\/,.'
]
export const CASCADED_ASCII_LEVELS_CHARSETS = (() => {
  let base = ''
  return ASCII_LEVELS_CHARSETS.map((charset) => {
    base = base + charset.normalize()
    return base
  })
})()
const CASCADED_ASCII_LEVELS_CHARSETS_ARRS = CASCADED_ASCII_LEVELS_CHARSETS.map(
  (charset) => Array.from(charset)
)
export class ASCIILevelsRSG extends CharsetLimitedRSG {
  constructor (level, exclusions = '', rng = getSeries32RNG()) {
    if (level < 0 || level >= ASCII_LEVELS_CHARSETS.length) {
      throw new RangeError(
        'level must be within [0, ' + ASCII_LEVELS_CHARSETS.length + ')'
      )
    }
    let arrCharSet = CASCADED_ASCII_LEVELS_CHARSETS_ARRS[level]
    for (const excludedChar of exclusions.normalize()) {
      arrCharSet = arrCharSet.map((char) => {
        if (char === excludedChar) {
          return ''
        }
        return char
      })
    }
    super(arrCharSet, rng)
  }
}
