document.addEventListener('DOMContentLoaded', () => {
  // Selectors
  const generateButton = document.getElementById('tag-generate')
  const amountInput = document.getElementById('lorem-amount')
  const formatDropdown = document.querySelector('#lorem-format .uk-custom-select button span') // Format dropdown
  const languageDropdown = document.querySelector('#lorem-language .uk-custom-select button span') // Language dropdown
  const outputContainer = document.getElementById('lorem-output') // Output container

  // Check if all necessary elements exist
  if (!generateButton || !amountInput || !formatDropdown || !languageDropdown || !outputContainer) {
    return
  }

  // Mapping for dropdown values to internal keys
  const formatMap = {
    'Văn bản': 'paragraph',
    Câu: 'sentences',
    Chữ: 'words'
  }

  const languageMap = {
    'Chữ Việt': 'Chữ Việt',
    'Chữ Anh': 'Chữ Anh',
    'Chữ Trung': 'Chữ Trung',
    'Chữ Nhật': 'Chữ Nhật'
  }

  // Function to generate Lorem Ipsum
  function generateLoremIpsum (format, amount, language) {
    const loremText = {
      paragraph: {
        'Chữ Việt': 'Đây là văn bản mẫu bằng tiếng Việt. Hãy sử dụng nó để thử nghiệm. Đây là văn bản mẫu bằng tiếng Việt. Hãy sử dụng nó để thử nghiệm.',
        'Chữ Anh': 'This is a sample text in English. Use it for testing purposes.',
        'Chữ Trung': '这是中文的示例文本。请用于测试。',
        'Chữ Nhật': 'これはテストに使用するための日本語のサンプルテキストです。'
      },
      sentences: {
        'Chữ Việt': 'Đây là câu tiếng Việt.',
        'Chữ Anh': 'This is an English sentence.',
        'Chữ Trung': '这是一个中文句子。',
        'Chữ Nhật': 'これは日本語の文です。'
      },
      words: {
        'Chữ Việt': 'mẫu văn bản',
        'Chữ Anh': 'sample text',
        'Chữ Trung': '示例文本',
        'Chữ Nhật': 'サンプルテキスト'
      }
    }

    // Validate format and language
    if (!loremText[format]) {
      return ''
    }
    if (!loremText[format][language]) {
      return ''
    }

    const result = []
    const textSamples = loremText[format][language]

    for (let i = 0; i < amount; i++) {
      result.push(textSamples)
    }

    return result.join(' ')
  }

  // Function to get selected value from custom dropdown
  function getSelectedDropdownValue (dropdown) {
    return dropdown ? dropdown.textContent.trim() : null
  }

  // Event listener for the generate button
  generateButton.addEventListener('click', () => {
    const formatLabel = getSelectedDropdownValue(formatDropdown) // Get the selected format label
    const languageLabel = getSelectedDropdownValue(languageDropdown) // Get the selected language label
    const format = formatMap[formatLabel] // Map label to internal key
    const language = languageMap[languageLabel] // Map label to internal key
    const amount = parseInt(amountInput.value, 10) // Get the amount

    if (!format || !language) {
      outputContainer.innerHTML = '<p class="uk-text-danger">Vui lòng chọn định dạng và ngôn ngữ.</p>'
      return
    }

    if (isNaN(amount) || amount <= 0) {
      outputContainer.innerHTML = '<p class="uk-text-danger">Vui lòng nhập số lượng hợp lệ.</p>'
      return
    }

    const loremIpsum = generateLoremIpsum(format, amount, language)

    if (!loremIpsum) {
      outputContainer.innerHTML = '<p class="uk-text-danger">Đã xảy ra lỗi khi tạo văn bản mẫu.</p>'
      return
    }

    // Display the generated Lorem Ipsum in HTML
    outputContainer.innerHTML = `<div class="uk-text-primary">${loremIpsum}</div>`
  })
})
